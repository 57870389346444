const Content = () => {
  return (
    <div
      className={` w-full relative bg-blue-800 overflow-hidden flex flex-col`}
    >
      <div className="flex items-center justify-center w-full gap-0 py-10 bg-gray-200">
        <div className="max-w-[1200px] w-[90%] flex items-stretch justify-evenly font-inter  flex-col gap-5 text-lg">
          <p className="text-xl font-bold font-poppins">1. INTRODUCTION</p>

          <p>
            1.1. Nexus Pro ("we" or "us") is committed to protecting your
            privacy. This Privacy Policy explains how we collect, use, disclose,
            and safeguard your personal information when you access or use the
            Nexus Pro platform and services, including but not limited to the
            Nexus Pro tokens EU/US and US/EU ("Services").
          </p>

          <p>
            1.2. By using the Services, you consent to the practices described
            in this Privacy Policy. If you do not agree to this Privacy Policy,
            do not use the Services.
          </p>

          <p className="text-xl font-bold font-poppins">
            2. INFORMATION WE COLLECT
          </p>

          <p>
            2.1. Personal Information: We may collect personal information,
            including but not limited to your name, email address, phone number,
            and government-issued identification.
          </p>

          <p>
            2.2. Financial Information: If you engage in financial transactions
            with Nexus Pro, we may collect information about your transactions,
            including cryptocurrency wallet addresses.
          </p>

          <p>
            2.3. Technical Information: We may collect technical information,
            including your IP address, device type, browser information, and
            usage patterns.
          </p>

          <p className="text-xl font-bold font-poppins">
            3. HOW WE USE YOUR INFORMATION
          </p>

          <p>
            3.1. Provide and Improve Services: We use your information to
            provide, maintain, and improve the Services, including enhancing
            user experience and developing new features.
          </p>

          <p>
            3.2. Communication: We may use your contact information to
            communicate with you about important updates, announcements, and
            marketing materials.
          </p>

          <p>
            3.3. Legal Compliance: We may use your information to comply with
            legal obligations, respond to legal requests, and enforce our terms
            and policies.
          </p>

          <p className="text-xl font-bold font-poppins">
            4. INFORMATION SHARING
          </p>

          <p>
            4.1. Third-Party Service Providers: We may share your information
            with third-party service providers that help us deliver, maintain,
            and improve the Services.
          </p>

          <p>
            4.2. Legal Compliance: We may disclose your information to comply
            with applicable laws, regulations, legal processes, or governmental
            requests.
          </p>

          <p className="text-xl font-bold font-poppins">5. SECURITY</p>

          <p>
            5.1. Data Security: We implement technical and organizational
            measures to protect your information from unauthorized access,
            disclosure, alteration, and destruction.
          </p>

          <p>
            5.2. User Responsibility: While we take reasonable steps to secure
            your information, you are responsible for maintaining the
            confidentiality of your account credentials and ensuring the
            security of your device.
          </p>

          <p className="text-xl font-bold font-poppins">
            6. INTELLECTUAL PROPERTY
          </p>

          <p>
            6.1. Ownership: Nexus Pro retains all rights, title, and interest in
            and to the Services, including intellectual property rights.
          </p>

          <p>
            6.2. Use of Content: Users may not reproduce, distribute, modify, or
            create derivative works of the material on the Nexus Pro website
            without explicit permission.
          </p>

          <p className="text-xl font-bold font-poppins">
            7. DISCLAIMER OF LIABILITY
          </p>

          <p>
            7.1. No Responsibility for Loss or Damage: Nexus Pro is not
            responsible for any loss or damage resulting from the use of the
            Services, including but not limited to market fluctuations,
            technical failures, and unauthorized access.
          </p>

          <p className="text-xl font-bold font-poppins">8. YOUR CHOICES</p>

          <p>
            8.1. Opt-Out: You may opt-out of receiving promotional
            communications from us by following the unsubscribe instructions
            provided in the communication.
          </p>

          <p className="text-xl font-bold font-poppins">
            9. CHANGES TO PRIVACY POLICY
          </p>

          <p>
            9.1. Amendments: We reserve the right to amend this Privacy Policy
            at any time. Changes will become effective immediately upon posting
            on the Nexus Pro website.
          </p>

          <p className="text-xl font-bold font-poppins">
            10. GOVERNING LAW AND DISPUTE RESOLUTION
          </p>

          <p>
            10.1. Governing Law: This Privacy Policy is governed by the laws of
            Switzerland.
          </p>

          <p>
            10.2. Dispute Resolution: Any dispute arising out of or in
            connection with this Privacy Policy shall be subject to the
            exclusive jurisdiction of the Swiss courts.
          </p>

          <p className="text-xl font-bold font-poppins">
            11. CONTACT INFORMATION
          </p>

          <p>
            If you have any questions about this Privacy Policy, please contact
            Nexus Pro at info@nexuspro.io. By using the Services, you
            acknowledge that you have read, understood, and agree to be bound by
            this Privacy Policy.
          </p>

          <p className="text-xl font-bold font-poppins">12. WHO WE ARE</p>

          <p>
            Nexus Pro AG (“we”, “us” or “our”) determines the means and purposes
            of processing of your Personal Data in relation to the Services
            provided to you (typically referred to as a “Data Controller”). Our
            registration number is CHF -354.021.134. You can contact us:
          </p>

          <p>By mail: Aussergrutstrasse 2, 6319 Allenwinden, Switzerland</p>

          <p className="flex flex-col">
            <span>By email: support@nexuspro.io</span>
            <span>Website: www.nexuspro.io</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Content;
