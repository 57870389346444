const partners = [
  {
    name: "Bitstorage",
    image: "/assets/images/logo-bitstorage.png",
    useuLink:
      "https://bitstorage.finance/spot/trading/USEUUSDT?interface=classic",
    euusLink:
      "https://bitstorage.finance/spot/trading/EUUSUSDT?interface=classic",
  },
  {
    name: "DexTrade",
    image: "/assets/images/logo-dex-trade.png",
    useuLink: "https://dex-trade.com/spot/trading/USEUUSDT?interface=classic",
    euusLink: "https://dex-trade.com/spot/trading/EUUSUSDT?interface=classic",
  },
  {
    name: "FinexBox",
    image: "/assets/images/logo-finexbox.png",
    useuLink: "https://www.finexbox.com/market/pair/useu-btc.html",
    euusLink: "https://www.finexbox.com/market/pair/euus-btc.html",
  },
  {
    name: "Latoken",
    image: "/assets/images/logo-latoken.png",
    useuLink: "https://latoken.com/exchange/USEU_USDT",
    euusLink: "https://latoken.com/exchange/EUUS_USDT",
  },
  {
    name: "AZbit",
    image: "/assets/images/logo-azbit.png",
    useuLink: "https://azbit.com/exchange/USEU_USDT",
    euusLink: "https://azbit.com/exchange/EUUS_USDT",
  },
];

const Partners = () => {
  return (
    <div className={`w-full relative h-full`}>
      <div
        className={`w-full h-full absolute top-0 right-0`}
        style={{
          background: "linear-gradient(315deg, #87caeb 13%, #0082e5 100%)",
        }}
      />
      <div className="relative z-20 flex flex-col items-center justify-center w-full px-4">
        <div className="flex-col items-center justify-center w-full text-white ">
          <div className="flex flex-col items-center justify-center my-16 text-3xl font-bold text-center">
            <p>CRYPTO EXCHANGE PARTNERS</p>
          </div>
        </div>
        <div className="flex flex-col md:flex-wrap md:flex-row md:justify-center w-full pb-8 md:gap-y-8">
          {partners.map((partner) => (
            <div
              className="flex flex-col items-center w-full md:w-1/3 mt-3 md:mt-0 min-w-60"
              key={partner.name}
            >
              <div className="w-1/4 md:w-1/2 md:h-16 flex flex-row items-center">
                <img
                  src={partner.image}
                  className="w-full"
                  alt={partner.name}
                />
              </div>
              <div className="flex flex-row gap-6 mt-3">
                <a href={partner.useuLink} target="_blank" rel="noreferrer">
                  <img
                    src="/assets/images/Nexus Pro_Icons_04-min.png"
                    className="w-10 h-10 md:w-12 md:h-12"
                    alt="Logo USEU"
                  />
                </a>
                <a href={partner.euusLink} target="_blank" rel="noreferrer">
                  <img
                    src="/assets/images/Nexus Pro_Icons_03-min.png"
                    className="w-10 h-10 md:w-12 md:h-12"
                    alt="Logo EUUS"
                  />
                </a>
              </div>
            </div>
          ))}
        </div>

        <a
          href="mailto:partnerships@nexuspro.io"
          target="_blank"
          rel="noreferrer"
          className="my-6 md:my-8 text-md inline-block rounded-xl xl:px-6 px-3 xl:py-3 font-inter py-2 text leading-normal transition duration-150 ease-in-out bg-[#0088CD] dark:text-gray text-white hover:shadow-[6px_5px_13px_0px_#0e6a94]"
        >
          Submit Application
        </a>
      </div>
    </div>
  );
};

export default Partners;
